import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Relay from "./components/pages/Relay";
import RelayBestPractices from "./components/pages/RelayBestPractices";
import RelayForm from "./components/pages/RelayForm";
import RelayDigital from "./components/pages/RelayDigital";
import WidgetEmbed from "./components/pages/WidgetEmbed";
import Ticker from "./components/Scoreboard/Ticker";
import Scoreboard from "./components/Scoreboard/Scoreboard";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/">
            <Relay />
          </Route>
          <Route path="/relay-bestpractices/" component={RelayBestPractices} />
          <Route path="/relay-form/" component={RelayForm} />
          <Route path="/relay-digital" component={RelayDigital} />
          <Route path="/widget-embed" component={WidgetEmbed} />
          <Route path="/scoreboard-ticker" component={Ticker} />
          <Route path="/scoreboard" component={Scoreboard} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
