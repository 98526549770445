import React, { Component } from "react";
import { Header, Footer, Hero } from "../layout";

class OverlayLP extends Component {
  render() {
    return (
      <div className="relay">
        <div className="overlay_lp__top">
          <Header type="normal" link="/" />
          <Hero>
            <div className="hero__content__box">
              <h1>Relay Community Challenge for St. Jude Best Practices</h1>
            </div>
            {/* <span className="caption">
              Photo taken in 2019 with St. Jude patient Grayson
            </span> */}
          </Hero>
        </div>
        <section className="inner-row gutters constrain overlay_lp__content bestpractices">
          {/* <h1>RelayFM Community Challenge for St. Jude Best Practices </h1> */}
          <p>
            The first rule of fundraising is simply to ask, and we have the tips
            and tools to help you! Read below to learn some of our top tips to
            help you raise money for the kids of St. Jude.
          </p>
          <p>
            <strong>Setting Your Fundraising Goal </strong>
          </p>
          <p>
            Make sure to share your fundraising goal with your friends and
            family! Using our Gifts that Give amounts are a great way to decide
            how much you want to raise for the kids of St. Jude.
          </p>
          <p>For example: </p>
          <ul>
            <li>$200 can help provide platelet count test for four patients</li>

            <li>$500 can help provide one platelet transfusion </li>

            <li>
              $1,000 can help provide two-thirds of the cost of one day of
              chemotherapy
            </li>

            <li>$2,500 can help provide sixteen days of oxygen </li>

            <li>
              $5,000 could help cover the cost of the daily room rate in the ICU
            </li>

            <li>
              $10,000 could help provide grocery gift cards for two years for
              one St. Jude family
            </li>
          </ul>
          <p>
            Start small, you can always increase your goal as milestones are
            hit!
          </p>
          <p>
            <strong>Deciding on incentives and milestones </strong>
          </p>
          <p>
            Below are several incentive and milestone ideas but feel free to
            create your own! Incentives don't have to cost additional money and
            can be as simple as:
          </p>
          <ul>
            <li>Set a donation goal to feed your pet a treat.</li>

            <li>
              Add donor names to a “thank you wall” that you take photos of and
              post online
            </li>

            <li>
              Draw a photo from a list of suggestions with your eyes closed for
              a specific donation amount and post it online
            </li>

            <li>
              Set a milestone goal to unlock a friend/family game night or movie
              night!
            </li>
          </ul>
          <p>
            What do your friends and family tease you about constantly? Include
            some of those things as incentives and milestones as well! Also,
            don't feel like you have to stick with any of your incentives for
            the entire campaign. You can switch things up and do a limited time
            incentive for one week only, for example.{" "}
          </p>
          <p>
            <strong>Create engagement with Tiltify polls</strong>
          </p>
          <p>
            Utilize Tiltify polls as a great way to create engagement with your
            donors. Learn how to set those up{" "}
            <a href="https://info.tiltify.com/support/solutions/articles/43000011861-adding-incentives-polls">
              here
            </a>
            .
          </p>
          <p>
            <strong>
              Are you streaming your gameplay on Facebook, Twitch, or Youtube?{" "}
            </strong>
          </p>
          <p>
            While streaming your game is not required to participate, if you do
            decide to stream we have a suite of tech tools to help make your
            fundraising easier. Check out our official toolkit at{" "}
            <a href="https://www.stjude.org/playlivetoolkit">
              stjude.org/playlivetoolkit
            </a>{" "}
            for all your streaming overlays, donation alerts, chatbot, starting
            soon screens, and more.{" "}
          </p>
          <p>
            <strong>Promoting your campaign </strong>
          </p>
          <p>
            Let everyone know when and where your fundraising event will take
            place! We have additional resources in our{" "}
            <a href="https://www.stjude.org/playlivetoolkit">toolkit</a> and{" "}
            <a href="https://playlive.experience.stjude.org/relay_social.zip">
              social media assets
            </a>{" "}
            to help you do the following:{" "}
          </p>
          <ul>
            <li>Set up your incentive and social graphics</li>

            <li>
              Plan and schedule out any social media posts for when your
              campaign is live
            </li>
          </ul>
          <p>
            <strong>During your campaign </strong>
          </p>
          <p>
            While fundraising for St. Jude kids, HAVE FUN. Asking for donations
            to reach your milestones and goals is expected, so don't hesitate to
            do so continually throughout your campaign. Remind your friends and
            family to document any important moments so you can include those in
            any celebration events.{" "}
          </p>
          <p>
            <strong>CELEBRATE! </strong>
          </p>
          <p>
            Celebrate with your friends and family! Your support matters because
            the majority of our funding comes from individual contributors.
            Remember that every dollar raised and every single campaign created
            is helping raise awareness and funds for the life-saving work at St.
            Jude.{" "}
          </p>
        </section>
        <Footer />
      </div>
    );
  }
}

export default OverlayLP;
