import React from "react";

import SJHorizontalFull from "../../images/sj_horizontal.png";
import ribbon from "../../images/ccam-flag.png";

const Header = (props) => {
  return (
    <header>
      <section className="banner red">
        <section className="logo">
          {props.type === "normal" ? (
            <div>
              <a href={props.link}>
                <img
                  src={SJHorizontalFull}
                  alt="St. Jude Childrens Research Hospital"
                />
              </a>
            </div>
          ) : (
            <div>
              <a href="/" alt="St. Jude" className="sjlogo">
                <span>St. Jude</span>
              </a>

              <a href="/" alt="Play Live" className="program-name">
                <span>
                  <strong>St. Jude</strong>
                  <br />
                  Play Live<span className="logo-subsite-trademark">®</span>
                </span>
              </a>
            </div>
          )}
        </section>
        <div className="ccam-ribbon">
          <img alt="CCAM Ribbon" src={ribbon} />
        </div>
      </section>
    </header>
  );
};

export default Header;
