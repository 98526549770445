import React, { useState } from "react";

const Ticker = () => {
  const [stephenCounter, setStephenCounter] = useState(null);
  const [mykeCounter, setMykeCounter] = useState(0);
  if (stephenCounter === null) {
    fetch(process.env.REACT_APP_APIGATEWAY, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ function: "scan" }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(
          "data 0:",
          data.Items[0].id,
          data.Items[0].person,
          data.Items[0].score,
          "data 1:",
          data.Items[1].id,
          data.Items[1].person,
          data.Items[1].score
        );
        setStephenCounter(data.Items[1].score);
        setMykeCounter(data.Items[0].score);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const stephenIncrementCounter = () => setStephenCounter(stephenCounter + 1);
  let stephenDecrementCounter = () => setStephenCounter(stephenCounter - 1);
  if (stephenCounter <= 0) {
    stephenDecrementCounter = () => setStephenCounter(1);
  }

  const mykeIncrementCounter = () => setMykeCounter(mykeCounter + 1);
  let mykeDecrementCounter = () => setMykeCounter(mykeCounter - 1);
  if (mykeCounter <= 0) {
    mykeDecrementCounter = () => setMykeCounter(1);
  }

  const submitChanges = () => {
    console.log("going");
    fetch(process.env.REACT_APP_APIGATEWAY, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        function: "update",
        id: 0,
        person: "stephen",
        score: stephenCounter,
        id2: 1,
        person2: "myke",
        score2: mykeCounter,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("update?", data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="scoreboard-ticker">
      <div className="wrapper">
        <div className="container stephen">
          <h1>Stephen</h1>
          <button onClick={stephenIncrementCounter}>&and;</button>
          <p>{stephenCounter === null ? 0 : stephenCounter}</p>
          <button onClick={stephenDecrementCounter}>&or;</button>
        </div>
        <div className="container myke">
          <h1>Myke</h1>
          <button onClick={mykeIncrementCounter}>&and;</button>
          <p>{mykeCounter}</p>
          <button onClick={mykeDecrementCounter}>&or;</button>
        </div>
      </div>
      <div className="button-wrapper">
        <button onClick={submitChanges}> Submit</button>
      </div>
    </div>
  );
};

export default Ticker;
