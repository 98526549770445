import React, { useEffect, useState } from "react";
//import axios from "axios";
import * as tiltify from "tiltify-lib";
import WidgetEmbedProgressBar from "../layout/WidgetEmbedProgressBar";

const RelayWidget = () => {
  const [raised, setRaised] = useState(0);
  const [goal, setGoal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function GetCampaignData() {
      const c = await tiltify.GetCampaign(
        process.env.REACT_APP_TEAM_CAMPAIGN,
        true
      );
      setRaised(Number(c.total_amount_raised.value));
      setGoal(Number(c.goal.value.replace(/\.00$/, "")));
    }
    GetCampaignData();
  }, []);

  useEffect(() => {
    setPercent(Math.round(100 * (parseFloat(raised) / parseFloat(goal))));
    setProgress(percent > 100 ? 100 : percent);
  }, [goal, percent, raised]);

  return (
    <div className="relay">
      <WidgetEmbedProgressBar raised={raised} goal={goal} progress={progress} />
    </div>
  );
};
export default RelayWidget;
