const axios = require("axios");
const https = require("https");

const axiosInstance = axios.create({
  //5 sec timeout
  timeout: 5000,
  httpsAgent: new https.Agent({ keepAlive: false }),
  //follow up to 10 HTTP 3xx redirects
  maxRedirects: 10,
  //cap the maximum content length we'll accept to 50MBs, just in case
  maxContentLength: 50 * 1000 * 1000,
});

const causeApi = 'cause';
const publicApi = 'public';
let apiMode = causeApi;

function SetUserMode() {
  apiMode = publicApi;
}
function SetCauseMode() {
  apiMode = causeApi;
}
function GetMode() {
  return apiMode;
}

let authEnabled = true;
function SetAuthEnabled(state) {
  authEnabled = state;
}

/**
 * For usage with a tiltify proxy, disables AuthToken and overrides V5 URL.
 * @param {string} url The base URL to use instead of tiltify
 */
function UseProxy(baseURL) {
  SetAuthEnabled(false);
  OverrideBaseV5URL(baseURL);
}

/**
 * Just waits asynchronously for the given time. Useful for Tiltify timeouts
 * @param {number} seconds
 * @returns
 */
function WaitForSeconds(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, seconds * 1000);
  });
}

const request = (endpoint, method = "get") => {
  let config = {
    method: method,
    url: `https://tiltify.com/api/v3/${endpoint}`,

    headers: {
      Authorization: "Bearer " + process.env.TILTIFY_ACCESS_TOKEN,
    },
  };
  return axiosInstance(config);
};

let v5Token = null;
let v5TokenExpiration = 0;
//
let v5RefreshToken = null;
let tokenUrl = `https://5r4qun41md.execute-api.us-east-1.amazonaws.com/api/tiltify/token/`;
let refreshUrl = null;



function OverrideAuthToken(token, expiration, refresh = null) {
  v5Token = token;
  v5TokenExpiration = expiration;
  v5RefreshToken = refresh;
}
/**
 * Sets up tiltify-lib's auth tokens from the JSON response from a tiltify auth request
 * @param {{access_token:string,created_at:string,expires_in:number,refresh_token:string}} data 
 */
function SetAuthData(data) {
  v5Token = "Bearer " + data.access_token;
  v5TokenExpiration = Date.parse(data.created_at) + (1000 * data.expires_in);
  if (data.refresh_token) v5RefreshToken = data.refresh_token;
}



function CheckClientIDSecret() {
  let errMsg = "";
  if (
    !process.env.hasOwnProperty("TILTIFY_CLIENT_ID") ||
    process.env.TILTIFY_CLIENT_ID.length == 0
  ) {
    errMsg += "process.env.TILTIFY_CLIENT_ID is missing! ";
  }
  if (
    !process.env.hasOwnProperty("TILTIFY_SECRET") ||
    process.env.TILTIFY_SECRET.length == 0
  ) {
    errMsg += "process.env.TILTIFY_SECRET is missing!";
  }
  if (errMsg.length > 0) throw new Error("GetAuthV5Token::" + errMsg);
}
/**
 * 
 * Requests a user access token for the given OAuth code. Switches tiltify-lib to user mode
 * @param {string} code The code returned from the Tiltify OAuth system
 * @param {string} redirect_uri The redirect_uri you passed to the Tiltify OAuth system. Do not encode!
 * @returns {Promise<{access_token:string,created_at:string,expires_in:number,refresh_token:string}>}
 */
async function GetUserAccessToken(code, redirect_uri, setUserMode = true) {
  const config = {
    method: "post",
    url: `https://5r4qun41md.execute-api.us-east-1.amazonaws.com/api/tiltify/token/`,
    data: {
      grant_type: "authorization_code",
      redirect_uri,
      code
    }
  }
  try {
    const resp = await axiosInstance(config);
    if (setUserMode) {
      SetAuthData(resp.data);
      SetUserMode();
    }
    return resp.data;
  } catch (e) {
    console.error("tiltify::GetUserAccessToken:", e.message);
    throw e;
  }
}

async function GetAuthV5Token() {
  if (!authEnabled) return null; //for use with lambda proxy,
  if (Date.now() > v5TokenExpiration) {
    let config;
    if (apiMode == causeApi) {
      CheckClientIDSecret();
      //console.log("GetAuthV5Token::Token expired at",new Date(v5TokenExpiration).toLocaleString(),". Fetching new one...");
      config = {
        method: "post",
        url: 'https://v5api.tiltify.com/oauth/token',
        data: {
          client_id: process.env.TILTIFY_CLIENT_ID,
          client_secret: process.env.TILTIFY_SECRET,
          grant_type: "client_credentials",
          scope: "cause"
        }
      }
    } else {
      config = {
        method: "post",
        url: tokenUrl,
        data: {
          grant_type: "refresh_token",
          refresh_token: v5RefreshToken
        }
      }
    }
    try {
      const resp = await axiosInstance(config);
      SetAuthData(resp.data);
    } catch (e) {
      console.error("tiltify::GetAuthV5:", e.message);
    }
  }

  return v5Token;
}

function GetTokenExpiration() {
  return v5TokenExpiration;
}

let baseV5URL = 'https://v5api.tiltify.com/api/'
function OverrideBaseV5URL(url) {
  baseV5URL = url;
}
/**
 * Generic request to `https://v5api.tiltify.com/api/${endpoint}`
 * Will auto-retry 5 times on timeout or auth-fail
 * @param {string} endpoint
 * @param {string} method
 * @param {number} retry
 * @returns
 */
async function requestV5(endpoint, method = "get", retry = 5) {
  const token = await GetAuthV5Token();

  let config = {
    method: method,
    url: `${baseV5URL}${endpoint}`,
    headers: {
      Authorization: token,
    }
  };

  try {
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (retry > 0) {
        //if we lost auth, just retry which will get a fresh token
        //if we timeout, or bad gateway, just keep retrying
        if (
          error.response.status == 401 ||
          error.response.code === "ECONNABORTED" ||
          error.response.status == 502
        ) {
          //if we timed out, wait half a second and then retry
          //console.log("Key expired or timed out. Retrying...");
          await WaitForSeconds(0.5);
          return await requestV5(endpoint, method, retry - 1);
        }
      } else console.error("Request V5:: Retried 5 times!");
      //console.error(error);
    }
    throw error; //not our problem
  }
}

async function GET(endpoint) {
  let res;
  try {
    res = (await request(endpoint)).data;
  } catch (err) {
    //console.log(err.response);
    if (err.response && err.response.data) {
      res = err.response.data;
    } else throw err;
  }
  //console.log("GET::", res);

  if (res.meta.status !== 200)
    throw new Error(
      `${res.meta.status}: ${endpoint} : ${res.error.title} : ${res.error.detail}`
    );
  return res.data;
}
/**
 * Helper method for calling the V5 API. Tiltify error responses will be returned as data.
 * @param {string} endpoint `https://v5api.tiltify.com/api/${endpoint}`
 * @param {*} limit Appends '&limit=limit'
 * @param {*} cursor Appends '&after=cursor'. If this is null, will not append anything as Tiltify throws an internal error
 * @returns {Promise<{data:{},metadata:{}}|{error:{}}>}
 */
async function GETV5(endpoint, limit = 100, cursor = null) {
  let res;

  const url =
    `${endpoint}${endpoint.includes("?") ? "&" : "?"}limit=${limit}` +
    (cursor != null ? `&after=${cursor}` : "");

  try {
    res = (await requestV5(url)).data;
  } catch (err) {
    if (err.response && err.response.data) {
      res = err.response.data;
    } else throw err;
  }

  return res;
}
/**
 *
 * A wrapper around GETV5 that handles paginating results via cursor.
 * @param {string} endpoint
 * @param {number} [max=Number.MAX_VALUE] The max size of the array returned.
 * @param {number} [batchLimit=100] The limit size to use when making requests. Generally you dont need to change this
 * @returns
 */
async function GETV5_Array(endpoint, max = Number.MAX_VALUE, batchLimit = 100) {
  /*
  TODO: might change this so if there is an error, it returns the partial array and the error object
  Throwing an error right now isn't consistent with the GETV5 logic, which returns the error as a response.
  */
  let cursor = null;
  const data = [];
  
  do {
    const resp = await GETV5(endpoint, Math.min(batchLimit, max-data.length), cursor);
    if (resp.error) throw new Error(JSON.stringify(resp.error) + endpoint + Math.min(batchLimit, max).toString() + cursor);
    data.push(...resp.data);
    cursor = resp.metadata.after;
  } while (cursor != null && data.length < max);
  return data;
}

/**
 * Gets the Campaign for the id. If supporting is set true, this will return the Supporting Campaign instead (if possible).
 * @param {number} id
 * @param {boolean} isTeam false
 * @param {boolean} supporting false
 */
async function GetCampaign(id, isTeam = false, supporting = false) {
  let res;
  try {
    res = await GETV5(`${apiMode}/${isTeam ? "team_" : ""}campaigns/${id}`);
  } catch (error) {
    console.error(error);
    return null;
  }
  if (
    supporting &&
    res.hasOwnProperty("supportingCampaignId") &&
    res.supportingCampaignId != null
  ) {
    const scid = res.supportingCampaignId;
    try {
      res = await GetCampaign(scid, false);
    } catch (err) {
      console.warn(
        `GetCampaign(${id})::supporting: Found supportingCampaignId ${scid} but got an error:`,
        err
      );
    }
  }
  if (res.error) {
    if (res.error.status == 404) return null;
    throw new Error(JSON.stringify(res.error));
  }
  return res.data;
}

/**
 * Returns the Team data or null
 * @param {string} slug
 * @returns
 */
async function FindTeam(slug) {
  let resp = await GETV5(`public/teams/by/slug/${slug}`);
  if (resp.error) {
    if (resp.error.status == 404) return null;
    throw new Error(JSON.stringify(resp.error));
  }
  return resp.data;
}

/**
 * Returns the User data or null
 * @param {string} slug
 * @returns
 */
async function FindUser(slug) {
  let resp = await GETV5(`public/users/by/slug/${slug}`);
  if (resp.error) {
    if (resp.error.status == 404) return null;
    throw new Error(JSON.stringify(resp.error));
  }
  return resp.data;
}
/**
 * Returns the campaign data or null
 * @param {string} userslug
 * @param {string} campaignslug
 * @param {boolean} isTeam false
 * @returns
 */
async function FindCampaign(userslug, campaignslug, isTeam = false) {
  //public/campaigns/by/slugs/{user_slug}/{campaign_slug}
  //https://tiltify.com/+yarrforthecause/gamingforthekids
  let resp;
  if (isTeam) {
    resp = (await GETV5(`${apiMode}/team_campaigns/by/slugs/${userslug}/${campaignslug}`));
  } else {
    resp = (await GETV5(`${apiMode}/campaigns/by/slugs/${userslug}/${campaignslug}`));
  }

  if (resp.error) {
    if (resp.error.status == 404) return null;
    throw new Error(JSON.stringify(resp.error));
  }
  return resp.data;
}

async function GetTeam(team) {
  return await GET(`teams/${team}`);
}

async function GetTargets(id, isTeam = false) {
  return await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/targets`);
}
/**
 * Gets the milestones for a given campaign ID
 * @param {string} id campaign ID
 * @param {boolean} isTeam false
 * @returns
 */
async function GetMilestones(id, isTeam = false) {
  return await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/milestones`);
}
/**
 * Gets the next milestone for a campaign
 * @param {number} id Campaign ID number
 * @param {boolean} isTeam false
 * @param {number} amountRaised (optional) if not null, use this instead of fetching the campaign
 * @returns
 */
async function GetNextMilestone(id, isTeam = false, amountRaised = null) {
  if (amountRaised === null) {
    const c = await GetCampaign(id, isTeam);
    if (c) amountRaised = c.amount_raised.value;
    else {
      console.warn("GetNextMilestone:: couldn't pull campaign for " + id);
      return null;
    }
  }
  //filter out milestones we've already hit, then sort them so the next milestone is index 0
  const milestones = (await GetMilestones(id, isTeam))
    .filter((value) => {
      return value.amount && value.amount.value > amountRaised;
    })
    .sort((a, b) => {
      return a.amount.value - b.amount.value;
    });
  if (milestones.length < 1) return null;
  return milestones[0];
}
/**
 * Gets the polls for a campaign
 * @param {string} id campaign ID
 * @param {boolean} isTeam false
 * @returns
 */
async function GetPolls(id, isTeam = false) {
  return (await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/polls`));
}

/**
 * Gets a single poll for a campaign by ID
 * @param {string} campaignID
 * @param {string} pollID Must by V5 ID!  Legacy ID will return BAD REQUEST
 * @param {boolean} isTeam
 * @returns
 */
async function GetPoll(campaignID, pollID, isTeam = false) {
  return await GETV5(
    `public/${isTeam ? "team_" : ""}campaigns/${campaignID}/polls/${pollID}`
  );
}

/**
 * Gets the schedule for a given campaign
 * @param {string} id campaign ID
 * @param {boolean} isTeam false
 * @returns
 */
async function GetSchedule(id, isTeam = false) {
  return await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/schedules`);
}
/**
 * Gets the rewards for a given campaign
 * @param {string} id
 * @param {boolean} isTeam false
 * @returns
 */
async function GetRewards(id, isTeam = false) {
  return (await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/rewards`));
}

/**
 * This does not paginate donations, just asks for an arbitrary amount
 * @param {string} id campaign ID
 * @param {boolean} isTeam false
 * @param {number} count 10
 * @returns
 */
async function GetCampaignDonations(id, isTeam = false, count = 10) {
  //?count=
  return (await GETV5(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/donations`, count)).data;
}

/**
 * Gets a Fundraising Event by id
 * @param {string} fundraiserID
 * @returns
 */
async function GetFundraiser(fundraiserID) {
  return (await GETV5(`${apiMode}/fundraising_events/${fundraiserID}`)).data;// (sjFundraiserUrl + fundraiserID + "?&status=published")).totalAmountRaised.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
/**
 * Get's the campaign for the team. If campaign is null, then all campaigns will be returned
 * @param {number | string} team Either the ID or the slug
 * @param {number | string | null} campaign Either the ID or the slug
 */
async function GetTeamCampaign(team, campaign = null) {
  if (campaign === null) return await GET(`teams/${team}/campaigns`);
  return await GET(`teams/${team}/campaigns/${campaign}`);
}

async function GetAllTeamCampaigns() {
  ///api/cause/team_campaigns
  let after = '';
  let campaigns = [];
  let resp;

  do {
    resp = (await requestV5(`${apiMode}/team_campaigns?limit=100${after}`)).data;
    campaigns = campaigns.concat(resp.data);
    //shouldContinue = resp.data.length == 100 && resp.metadata.after != null;
    after = "&after=" + resp.metadata.after;
  } while (resp.data.length == 100 && resp.metadata.after != null);
  return campaigns;
}
/**
 * Get's all campaigns for a User ID
 * @param {string} id
 * @returns
 */
async function GetUserCampaigns(id) {
  let resp;
  resp = await GETV5_Array(`public/users/${id}/campaigns`);
  return resp;
}
/**
 * Gets all campaigns for a Team ID
 * @param {string} id
 * @returns
 */
async function GetTeamCampaigns(id) {
  return await GETV5_Array(`public/teams/${id}/team_campaigns`);
}

async function GetCause(id) {
  const resp = await GETV5(`public/causes/${id}`);
  if (resp.error) {
    if (resp.error.status == 404) return null;
    throw new Error(JSON.stringify(resp.error));
  }
  return resp.data;
}

/**
 *
 * Gets the donor leaderboard for a given campaign ID
 * @param {string} id event ID
 * @param {boolean} isTeam false
 * @param {Number} max The number of leaderboard entries you want. 
 * @returns
 */
async function GetLeaderboard(id, isTeam = false, max = Number.MAX_VALUE) {
  return await GETV5_Array(`${apiMode}/${isTeam ? 'team_' : ''}campaigns/${id}/donor_leaderboard`, max);
}

/**
 * Gets the user_leaderboards for a Team Campaign.
 * @param {string} id team ID
 * @param {Number} [max=Number.MAX_VALUE]  Defaults to MAX_VALUE which will return everything
 * @returns 
 */
async function GetTeamUserLeaderboard(id, max = Number.MAX_VALUE) {
  return await GETV5_Array(`public/team_campaigns/${id}/user_leaderboards`, max);
}



/**
 * Gets the user_leaderboard for the given fundraising_event id.
 * @param {string} id event ID
 * @param {number} [count=20]  Defaults to 20
 * @returns
 */
async function GetEventUserLeaderboard(id, count = 20) {
  return await GETV5_Array(
    `${apiMode}/fundraising_events/${id}/user_leaderboard`, count);
}

module.exports = {
  requestV5,
  GETV5,
  GETV5_Array,
  FindCampaign,
  FindTeam,
  FindUser,
  GetAuthV5Token,
  OverrideAuthToken,
  GetCampaign,
  GetCampaignDonations,
  GetPolls,
  GetPoll,
  GetFundraiser,
  GetMilestones,
  GetNextMilestone,
  GetRewards,
  GetSchedule,
  GetTeam,
  GetTargets,
  GetUserCampaigns,
  GetTeamCampaigns,
  GetCause,
  GetLeaderboard,
  SetUserMode,
  SetCauseMode,
  GetMode,
  GetUserAccessToken,
  SetAuthData,
  OverrideBaseV5URL,
  SetAuthEnabled,
  UseProxy,
  GetTokenExpiration,
  GetTeamUserLeaderboard,
  GetEventUserLeaderboard
}
