import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
// import "react-datepicker/dist/react-datepicker.css";


function TimestampPicker(props) {
  let startDate = new Date('January 01, 2022 00:00:00');
  let endDate = new Date('December 31, 2022 11:59:00');
  // endDate.setMonth(endDate.getMonth() + 1)


  const [dateStrt, onChangeStrt] = useState(startDate);
  const [dateEnd, onChangeEnd] = useState(endDate);
  const [calendarConfirm, setCalendarConfirm] = useState('')
  
  // console.log("Props Start", props.Start, "S:", dateStrt, "E", dateEnd);

  const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();

    month = month.length < 2 ? '0' + month : month
    day = day.length < 2 ? '0' + day : day
    minutes = minutes < 10 ? "0" + minutes : minutes

    const fulldate = year + '-' + month + '-' + day;
    const fulltime = hours + ':' + minutes;

    console.log(fulldate, fulltime)

    return fulldate + ' ' + fulltime;
}

  const handleStart= props.handleStart;
  const handleEnd = props.handleEnd;

  const calendarClass = () => {
    setCalendarConfirm(' checked')
    setTimeout(() => {
      setCalendarConfirm('')
    }, 5000);
  }

  return (
    <div>
      <div className="date-picking">
        <label>Start Date</label>
        <DateTimePicker
          format="y-MM-dd h:mm a"
          onChange={onChangeStrt}
          value={dateStrt}
          defaultValue={dateStrt}
          clearIcon={null}
          // disableClock
        />
        <label>End Date</label>
        <DateTimePicker
          format="y-MM-dd h:mm a"
          onChange={onChangeEnd}
          value={dateEnd}
          // disableClock
          clearIcon={null}
          minDate={dateStrt}
        />
        <button
          className={'cta-secondary' + calendarConfirm}
          onClick={() => {handleStart(formatDate(dateStrt)); handleEnd(formatDate(dateEnd)); calendarClass() }}
        >
          Confirm Dates
        </button>
      </div>
    </div>
  );
}

export default TimestampPicker;
