import React from "react";

const WidgetEmbedProgressBar = (props) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <div className="progress_wrapper embed">
      <section className="inner-row gutters constrain overlay_lp__content progress">
        <div className="col-12">
          <div className="progress_wrapper__embed_text">
            <p className="embed_title">
              <strong>Relay for St. Jude</strong>
            </p>
          </div>
          <div className="progress_wrapper__therm">
            <div
              className="progress_wrapper__therm__inner"
              style={{
                width: `${props.progress}%`,
              }}
            ></div>
            <span className="progress_wrapper__raised">
              {formatter.format(props.raised)} Raised
            </span>
            <span className="progress_wrapper__goal">
              Goal:{" "}
              {props.goal - Math.floor(props.goal) !== 0
                ? formatter.format(props.goal)
                : "$" + formatNumber(props.goal)}
            </span>
          </div>
        </div>
        <div className="col-12">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://donate.tiltify.com/0220c3fa-fc0e-4ae5-9dd9-174c956d5d1c/incentives"
          >
            <span className="cta-embed-primary">Donate Now</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default WidgetEmbedProgressBar;
