import React from "react";

const Hero = (props) => {
  return (
    <section className="hero_new">
      <section className="hero_new__content">{props.children}</section>
      {props.caption && <span className="caption">{props.caption}</span>}
    </section>
  );
};

export default Hero;
