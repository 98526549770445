import React from "react";
import { Header, Footer, Hero } from "../layout";
import { IFrame } from "../elements";

const iframes = {
  wufoo:
    '<iframe height="712" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" scrolling="no" sandbox="allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin" src="https://stjudeevents.wufoo.com/embed/rw6meho0umpjn3/"> <a href="https://stjudeevents.wufoo.com/forms/rw6meho0umpjn3/">Fill out my Wufoo form!</a> </iframe>',
};

const OverlayLP = () => {
  return (
    <div className="relay">
      <div className="overlay_lp__top form">
        <Header type="normal" link="/" />
        <Hero>
          <div className="hero__content__box">
            {/* <h1>Relay FM Matching Gift and DAF Credit 2022</h1> */}
          </div>
          {/* <span className="caption">
              Photo taken in 2019 with St. Jude patient Grayson
            </span> */}
        </Hero>
      </div>
      <section className="inner-row gutters constrain overlay_lp__content bestpractices">
        <div className="col-12">
          <IFrame iframe={iframes["wufoo"]} />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default OverlayLP;
